<template>
    <div
        class="fill-height"
        :class="{ 'in-modal': inModal }"
    >
        <Form
            ref="elFormObj"
            as=""
            v-slot="{ errors: formErrors, meta: formMeta, handleReset }"
        >
            <div>
                <Dimmer
                    :hide-spinner="false"
                    v-model="progress"
                >
                    <template #content>
                        <form
                            v-if="!signUpComplete"
                            @submit="onSubmit($event)"
                            @reset.prevent="handleReset"
                            class="fill-height"
                        >
                            <!-- Email -->
                            <v-row
                                class="mt-4"
                                justify="center"
                                dense
                            >
                                <v-col
                                    cols="12"
                                    md="4"
                                    class="d-flex flex-column align-stretch"
                                >
                                    <Field
                                        name="emailAddress"
                                        label="Email"
                                        v-slot="{ field, errors }"
                                        v-model="form.emailAddress"
                                        :rules="{
                                            required: true,
                                            email: true
                                        }"
                                    >
                                        <v-text-field
                                            v-bind="field"
                                            :model-value="field.value"
                                            id="field_emailAddress"
                                            name="emailAddress"
                                            label="Email"
                                            type="email"
                                            autocorrect="off"
                                            autocapitalize="none"
                                            autocomplete="email"
                                            variant="outlined"
                                            :error-messages="errors"
                                            density="comfortable"
                                            required
                                        ></v-text-field>
                                    </Field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="2"
                                    class="d-flex flex-column align-stretch"
                                >
                                    <v-btn
                                        color="secondary"
                                        type="submit"
                                        size="large"
                                        :disabled="!lo_isEmpty(formErrors)"
                                        variant="flat"
                                    >
                                        Subscribe
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </form>
                        <div
                            v-else
                            class="text-center"
                        >
                            <h4>Thanks for signing up!</h4>
                            {{ form.emailAddress }} has been subscribed.
                        </div>
                    </template>
                </Dimmer>
            </div>
        </Form>
    </div>
</template>

<script lang="ts" setup>
    import type { Field, Form } from 'vee-validate';
    import { useGlobalAlert, useNewsletter } from '~/composables';
    import Dimmer from '~/components/Dimmer/Dimmer.vue';
    import { isError as lo_isError } from 'es-toolkit';
    import { isEmpty as lo_isEmpty } from 'es-toolkit/compat';
    import type { NewsletterSignup } from '~/types';
    import { NewsletterSignupModel } from '~/models';
    import { initVeeRules } from '~/helpers/forms';
    
    initVeeRules([ 'required', 'email' ]);

    const props = defineProps({
        inModal: {
            type: Boolean,
            default: false
        }
    });

    const elFormObj = shallowRef<typeof Form>();
    const progress = ref(false);
    const useNewsletterObj = useNewsletter();
    const createGlobalAlert = useGlobalAlert();
    const signUpComplete = ref(false);

    const form: NewsletterSignup = reactive(NewsletterSignupModel.toPlainObject<NewsletterSignup>());

    async function onSubmit(e: Event): Promise<void> {
        e.preventDefault();
        progress.value = true;

        const validateObj = await elFormObj.value?.validate();

        if (!validateObj.valid) {
            progress.value = false;
            createGlobalAlert({
                text: 'Could not sign-up. Please try again.',
                show: true,
                type: 'error'
            });
            return;
        }

        const payload = NewsletterSignupModel.toPlainObject<NewsletterSignup>(form);
        const response = await useNewsletterObj.signUp(payload);
        progress.value = false;

        if (!lo_isError(response)) {
            signUpComplete.value = true;
        } else {
            createGlobalAlert({
                text: `Could not subscribe: ${response.message}`,
                show: true,
                type: 'error'
            });
        }
    }
</script>

<style lang="scss"></style>
